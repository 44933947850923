<template>
  <div class="passenger_box_two mt-2">
    <div class="passenger_header">
      <div v-if="device=='desktop'">
        <h3>{{ `${$t("booking.room")} ${index+1} - ${roomClass} `}}</h3>
      </div>
      <div class="guest_details_body" v-else>
        <h4>{{`${$t("booking.room-number")}  ${index+1}: ${roomClass}` }}</h4>
      </div>

    </div>
    <div class="passenger_body" style="background-image: url(/assets/img/passenger-box-banner.jpg)">
      <UserInformation
        v-for="(user, idx) in users"
        :key="idx"
        :index="idx"
        :user="user"
        :readOnly="readOnly"
        :supplier="supplier"
        :originDate="originDate"
        @updateUser="updateUserInformation"
      />
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import { fromBase64 } from '@/utils/base64Util';

export default {
  components: {
    UserInformation: () => import('@/components/booking/atoms/UserInformation'),
  },
  props: {
    room: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    supplier: {
      type: String,
      default: '',
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: {},
      readOnly: false,
      roomClass: '',
      users: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
    originDate() {
      return this.room.checkIn;
    },
  },
  created() {
    const retry = window.localStorage.getItem('retryState');
    const dataBook = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo')));
    const BookingDetailInfo = dataBook.data;
    const { sendingStage } = dataBook;
    const thisRoom = BookingDetailInfo.hotel.rooms[this.index];
    const { paxIds } = thisRoom;

    if (this.isExternal) {
      this.readOnly = true;
      for (let i = 0; i < paxIds.length; i += 1) {
        const user = BookingDetailInfo.paxList.find((a) => a.id === paxIds[i]);
        user.groupID = this.index;
        this.users.push(user);
      }
    } else if (retry !== 'null' && BookingDetailInfo.hotel.length > 0 && paxIds.length > 0 && sendingStage !== 'init') {
      const retryState = JSON.parse(retry);
      this.readOnly = retryState.readOnly;
      for (let i = 0; i < paxIds.length; i += 1) {
        const user = BookingDetailInfo.paxList.find((a) => a.id === paxIds[i]);
        user.groupID = this.index;
        this.users.push(user);
      }
    } else {
      let idx = 1;
      for (let i = 0; i < this.room.adults; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'adult',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
      for (let i = 0; i < this.room.children; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'child',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
      for (let i = 0; i < this.room.infant; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'infant',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
    }
    this.users.forEach((user) => this.$emit('updatedUser', user));

    // if (this.isExternal) {
    //   this.readOnly = true;
    //   this.user = BookingDetailInfo.paxList.find((a) => a.idNo === paxIds[0]);
    // } else if (retry !== 'null' && BookingDetailInfo.hotel.length > 0 && paxIds.length > 0 && sendingStage !== 'init') {
    //   const retryState = JSON.parse(retry);
    //   this.readOnly = retryState.readOnly;
    //   this.user = BookingDetailInfo.paxList.find((a) => a.id === paxIds[0]);
    //   this.user.groupID = this.index;
    // } else {
    //   this.user = {
    //     groupID: this.index,
    //     id: 1,
    //     generation: 'adult',
    //     firstName: '',
    //     lastName: '',
    //     paxType: 'M',
    //     email: '',
    //     phone: '',
    //     birthDate: '',
    //   };
    // }
    // this.$emit('updatedUser', this.user);
    this.roomClass = this.room.roomClassName;
  },
  methods: {
    updateUserInformation(personInfo) {
      Object.assign(this.user, personInfo);
      this.$emit('updatedUser', personInfo);
      if (personInfo.id === 1 && personInfo.validate) {
        this.user.email = personInfo.email;
        this.user.phone = personInfo.phone;
        this.user.birthDate = personInfo.birthDate;
        this.user.nationality = personInfo.nationality;
        this.$emit('updatedUser', this.user);
      }
    },
  },
};
</script>

<style scoped>
  .passenger_header {
    padding: 20px 20px;
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(1,98,172,1) 0%, rgba(38,135,209,1) 100%);
  }

  .passenger_body {
    padding: 20px 20px;
    /*min-height: 334px;*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .passenger_body:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(11 102 172 / 95%);
  }

  @media (max-width: 479px){
    .passenger_header {
      background : #fff;
      padding : 5px 15px
    }
    .guest_details_body h4 {
      color: rgb(33,37,41);
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .passenger_body {
      padding : 0px 15px;
    }

    .passenger_body:before{
      background:#fff;
    }
  }

</style>
